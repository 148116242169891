import { Routes } from '@/lib/constants';
import { Tasks } from '@prisma/client';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import axiosInstance from '../axios';
import QueryKeys from '../queryKeys';

const copyTask = async (id: string) => {
  const { data } = await axiosInstance.post<Tasks>(
    `${Routes.API.TASKS}/${id}/copy`
  );
  return data;
};

export const useCopyTask = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation<Tasks, AxiosError, string, unknown>({
    mutationFn: (id) => copyTask(id),

    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [QueryKeys.TASKS_HOME],
      });
    },
  });

  return mutation;
};
