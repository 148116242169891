import { useSession } from 'next-auth/react';

const SubscriptionBanner: React.FC = () => {
  const { data: session } = useSession();

  // Assuming subscription data is available in session
  if (!session) return null;
  const subscription = session?.user?.subscription?.[0];
  const isExpired =
    (subscription && new Date(subscription.endDate ?? '') < new Date()) ||
    subscription?.status !== 'ACTIVE' ||
    subscription.units?.length === 0;

  if (!isExpired) return null;

  return (
    <div
      data-testid="banner"
      className="w-full py-2 text-center bg-blue-100 text-blue-700 text-sm font-semibold"
    >
      Your subscription has expired.{' '}
      <a href="/settings/my-account/subscription" className="underline">
        Renew your subscription
      </a>{' '}
      to access all features.
    </div>
  );
};

export default SubscriptionBanner;
