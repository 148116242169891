import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/Accordion';
import EmptyScreen from '@/components/EmptyScreen';
import { EngineeringNotebookStepField } from '@prisma/client';

import dayjs from '@/components/Dayjs';
import { createContext, useContext } from 'react';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import {
  CreateSpaceReflectionReport,
  EngineeringNotebookStepsWithLikeAndImage,
  ReflectionSection,
} from 'types/models/Assignment';
import {
  FeelingIcon,
  getFeelingColor,
  setNotebookStepIcon,
} from '../Assignments/Log/LogItems';
import { InProgressIcon, NotStartedIcon } from '../Assignments/Progress';
import { useLikesAndMessagesReportManagerContext } from './useLikesAndMessagesReportManager';

type TestingResult = {
  studentId: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  entries: Record<string, EngineeringNotebookStepsWithLikeAndImage[]>;
  submitted: boolean;
  submittedAt: Date | null;
  active: boolean;
};

type RenderChallengeSetTestingResultsProps = {
  title: string;
  testingResults: TestingResult[] | undefined;
};

interface ChallengeSetReportManagerContextProps {
  RenderReflections: React.FC<{
    entries: ReflectionSection[];
    title: string;
  }>;
  RenderChallengeSetTestingResults: ({
    title,
    testingResults,
  }: RenderChallengeSetTestingResultsProps) => JSX.Element;
}

export const ChallengeSetReportManagerContext =
  createContext<ChallengeSetReportManagerContextProps | null>(null);
export const useChallengeSetReportManagerContext = () => {
  const context = useContext(ChallengeSetReportManagerContext);

  if (!context) {
    throw new Error(
      'useLikeManagerContext must be used within the LikeManagerContext Provider'
    );
  }
  return context;
};

export const ChallengeSetReportManagerProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { LikeIcon, MessageIcon } = useLikesAndMessagesReportManagerContext();

  const RenderChallengeSetTestingResults = ({
    title,
    testingResults,
  }: RenderChallengeSetTestingResultsProps) => {
    const renderEntry = (
      entry: EngineeringNotebookStepsWithLikeAndImage &
        CreateSpaceReflectionReport,
      entryIndex: number
    ) => (
      <div
        key={entryIndex}
        className="flex justify-between items-center p-3 border-b"
      >
        <div className="flex gap-3">
          <span className="flex gap-3 min-w-24 text-sm max-h-12">
            {setNotebookStepIcon()}
            <p>{entry.signedImageUrl ? 'SCREEN SHOT' : entry.field}</p>
          </span>
          <p className="flex-grow text-ellipsis pl-4">
            {entry.signedImageUrl ? (
              <img
                alt="Entry"
                width="300"
                height="200"
                src={entry.signedImageUrl}
              />
            ) : (
              entry.data
            )}
          </p>
        </div>
        <div className="flex items-center space-x-3">
          <MessageIcon
            entry={entry}
            entryType={
              entry.signedImageUrl ? 'image' : 'engineeringNotebookStep'
            }
            userInfo={entry.student}
          />
          <LikeIcon
            entry={entry}
            entryType={
              entry.signedImageUrl ? 'image' : 'engineeringNotebookStep'
            }
            assignmentType="CHALLENGES"
          />
          <Timestamp createdAt={entry.createdAt} />
        </div>
      </div>
    );

    return (
      <div className="pb-6">
        <div className="flex items-end pb-4">
          <h1>{title}</h1>
          <hr className="flex-grow ml-2" />
        </div>
        {testingResults?.map((testingResult, index) => (
          <Accordion
            type="single"
            collapsible
            className="w-full text-gray-500 border-b"
            key={index}
          >
            <AccordionItem value={index.toString() + 1}>
              <AccordionTrigger className="flex items-center p-3 ">
                <span className="flex w-3/4">{`${testingResult.firstName} ${testingResult.lastName}`}</span>
                <div className="flex w-1/4 justify-end">
                  {testingResult.submitted ? (
                    <BsFillCheckCircleFill color="green" size={20} />
                  ) : testingResult.entries &&
                    Object.entries(testingResult.entries).length > 0 ? (
                    <InProgressIcon width="w-5" height="h-5" />
                  ) : (
                    <NotStartedIcon width="w-5" height="h-5" />
                  )}
                </div>
              </AccordionTrigger>
              <AccordionContent className="p-3">
                {Object.entries(testingResult.entries).map(
                  ([iteration, entries], studentIndex) => (
                    <Accordion
                      key={studentIndex}
                      type="single"
                      collapsible
                      className="w-full"
                    >
                      <AccordionItem
                        key={studentIndex}
                        value={studentIndex.toString() + 1}
                        className={`${index < entries.length - 1 ? 'border-b' : ''
                          }`}
                      >
                        <AccordionTrigger className="flex items-center p-3">
                          <span className="font-bold">
                            Iteration {iteration}
                          </span>
                        </AccordionTrigger>
                        <AccordionContent>
                          {entries.length > 0 ? (
                            entries.map((entry, entryIndex) =>
                              renderEntry(entry, entryIndex)
                            )
                          ) : (
                            <div className="flex justify-center py-4 text-gray-500">
                              <span>No entries to display</span>
                            </div>
                          )}
                        </AccordionContent>
                      </AccordionItem>
                    </Accordion>
                  )
                )}
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        ))}
        {testingResults?.length === 0 && (
          <div className="flex justify-center py-4 text-gray-500">
            <span>No students have entries in this field.</span>
          </div>
        )}
      </div>
    );
  };
  const Timestamp = ({ createdAt }: { createdAt: Date }) => (
    <div className="flex text-sm justify-end text-ellipsis whitespace-nowrap ">
      <span>{dayjs(createdAt).local().format('MM/DD hh:mm a')}</span>
    </div>
  );

  const RenderReflections = ({
    entries,
    title,
  }: {
    entries: ReflectionSection[];
    title: string;
  }) => {
    return (
      <>
        <div className="flex items-end pb-4">
          <h1>{title}</h1>
          <hr className="flex-grow ml-2" />
        </div>
        {entries && entries.length > 0 ? (
          entries.map((entry: ReflectionSection, idx) => (
            <div
              className="flex flex-row justify-between items-center py-3 border-b text-gray-500 "
              key={idx}
            >
              <div className="flex justify-start gap-3">
                <div className="flex gap-4 items-center">
                  <span className="flex gap-4 min-w-28 font-bold max-h-12  ">
                    <span className="italic">
                      {entry.data
                        ? setNotebookStepIcon(
                          entry.field as EngineeringNotebookStepField
                        )
                        : FeelingIcon({ type: entry.type })}
                    </span>
                    {entry?.student?.firstName}
                  </span>
                </div>
                {entry.data ? (
                  <div className="flex-grow text-ellipsis pl-3  ">
                    <div className="flex items-center  align-middle">
                      <div className="text-sm text-gray-500 font-bold line-clamp-2  ">
                        {entry?.data}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex-grow text-ellipsis pl-3  ">
                    <div className="flex items-center  align-middle">
                      <div className="text-sm text-gray-500 font-bold capitalize">
                        {entry?.type?.toLowerCase()}
                      </div>
                      <span
                        className={`w-3.5 h-3.5 rounded-full ml-1 ${getFeelingColor(
                          entry?.type
                        )}`}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="flex justify-between items-center space-x-1">
                <MessageIcon
                  entryType={
                    entry.page ? 'engineeringNotebookStep' : 'feelingsTracker'
                  }
                  entry={entry}
                  userInfo={entry.student}
                />
                <LikeIcon
                  entry={entry}
                  entryType={
                    entry?.data ? 'engineeringNotebookStep' : 'feelingsTracker'
                  }
                  assignmentType="CHALLENGES"
                />
                <Timestamp createdAt={entry.createdAt} />
              </div>
            </div>
          ))
        ) : (
          <EmptyScreen
            description={'No entries have been submitted yet for this section.'}
            headline={'No Entries'}
          />
        )}
      </>
    );
  };

  return (
    <ChallengeSetReportManagerContext.Provider
      value={{
        RenderReflections,
        RenderChallengeSetTestingResults,
      }}
    >
      {children}
    </ChallengeSetReportManagerContext.Provider>
  );
};
