import { zodResolver } from '@hookform/resolvers/zod';
import { useQueryClient } from '@tanstack/react-query';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import React, {
  startTransition,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { FieldErrors, useForm, UseFormReturn } from 'react-hook-form';

import { showToast } from '@/components/Alert/toast';
import { taskCreateSchema } from '@lib-server/validation';
import { TasksType } from '@prisma/client';
import {
  TasksCreateFormData,
  TasksUpdateFormData,
} from 'types/models/Assignment';
import QueryKeys from '../react-query/queryKeys';

// React Query hooks
import { Tasks } from 'types/models/Task';
import { useCopyTask } from '../react-query/tasks/useCopyTask';
import { useCreateTask } from '../react-query/tasks/useCreateTasks';
import { useDeleteTask } from '../react-query/tasks/useDeleteTask';
import { useTasksAvailableUnits } from '../react-query/tasks/useTasksAvailableUnits';
import { useTasksTextLabels } from '../react-query/tasks/useTasksTextLabels';
import { useUpdateTask } from '../react-query/tasks/useUpdateTask';

interface FilterType {
  grade: string;
  unit: string;
  taskType: string;
}

interface TaskModalContextProps {
  // States
  currentTask: Tasks | null;
  taskType: TasksType; // 'CREATE_SPACE' or 'CHALLENGE_SET'
  submitMode: 'normal' | 'assignment';
  isCreateSpaceTaskModalOpen: boolean;
  isChallengeSetTaskModalOpen: boolean;
  currentStep: number;
  selectedNodes: string[]; // For challenge set
  selectedUnit: string; // For challenge set or create space

  // Form
  methods: UseFormReturn<TasksCreateFormData>;
  errors: FieldErrors<TasksCreateFormData>;

  // Booleans
  isLoading: boolean;
  isError: boolean;
  error: unknown;
  maxSteps: number;

  // Data
  filters: FilterType;
  textLabelsOptions: { label: string; value: string }[];
  unitsData: Array<{ label: string; value: string }> | undefined;

  // Modal handlers
  openCreateSpaceTaskModal: (task?: Tasks) => void;
  closeCreateSpaceTaskModal: () => void;
  openChallengeSetTaskModal: (task?: Tasks) => void;
  closeChallengeSetTaskModal: () => void;

  // Step-based Nav
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  navigateToStep: (step: number) => void;
  goNext: () => void;

  // Filter
  setFilters: (filters: FilterType) => void;

  // Submit & CRUD handlers
  onSubmitTaskCreation: (data: TasksUpdateFormData) => void;
  setSubmitMode: React.Dispatch<React.SetStateAction<'normal' | 'assignment'>>;
  setSelectedNodes: React.Dispatch<React.SetStateAction<string[]>>;
  setSelectedUnit: React.Dispatch<React.SetStateAction<string>>;

  // Additional CRUD
  handleDeleteTask: (taskId: string) => void;
  handleCopyTask: (task: Tasks) => void;
  handleEditTask: (task: Tasks) => void;
}

export const TaskModalContext = React.createContext<TaskModalContextProps>(
  {} as TaskModalContextProps
);

export const TaskModalProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  // Router, React Query
  const router = useRouter();
  const queryClient = useQueryClient();

  // State: modals
  const [isCreateSpaceTaskModalOpen, setIsCreateSpaceTaskModalOpen] =
    useState(false);
  const [isChallengeSetTaskModalOpen, setIsChallengeSetTaskModalOpen] =
    useState(false);

  // State: tasks, step, filters
  const [currentTask, setCurrentTask] = useState<Tasks | null>(null);
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [filters, _setFilters] = useState<FilterType>({
    grade: '',
    unit: '',
    taskType: '',
  });

  // State: form for both flows
  const methods = useForm<TasksCreateFormData>({
    resolver: zodResolver(taskCreateSchema),
  });
  const { errors } = methods.formState;

  // Challenge Set: store selected challenges, etc.
  const [selectedNodes, setSelectedNodes] = useState<string[]>([]);
  const [selectedUnit, setSelectedUnit] = useState<string>('');

  // Whether we are creating "CREATE_SPACE" or "CHALLENGE_SET"
  const [taskType, setTaskType] = useState<TasksType>('CREATE_SPACE');

  // Whether user clicked "Submit" (normal) or "Submit & Build Assignment"
  const [submitMode, setSubmitMode] = useState<'normal' | 'assignment'>(
    'normal'
  );

  const maxSteps = 2;

  // Session
  const { data, status } = useSession();
  const teacherId =
    data?.user.role === 'TEACHER' || data?.user.role === 'ADMIN'
      ? (data?.user.id as string)
      : '';

  // React Query: mutate hooks
  const {
    mutate: createTask,
    isPending: isLoadingCreatingNewTask,
    isError: isCreatingNewTaskError,
    error: creatingNewTaskError,
  } = useCreateTask();
  const {
    mutate: updateTask,
    isPending: isLoadingUpdatingTask,
    isError: isUpdatingTaskError,
    error: updatingTaskError,
  } = useUpdateTask(currentTask?.id ?? '', teacherId ?? '');
  const { mutate: deleteTask, isPending: isDeletePending } = useDeleteTask();
  const { mutate: copyTask, isPending: isCopyPending } = useCopyTask();

  // Additional data
  const { data: textLabelsData, isPending } = useTasksTextLabels({
    queryKey: [QueryKeys.TEXT_LABELS, teacherId ?? ''],
    enabled: !!teacherId,
  });
  const textLabelsOptions =
    textLabelsData?.map((label) => ({
      label: label.displayName,
      value: label.id,
    })) ?? [];

  const { data: unitsData } = useTasksAvailableUnits({
    teacherId: teacherId,
    queryKey: [QueryKeys.TASKS_UNITS, teacherId ?? ''],
  });

  // Combined loading & error
  const isLoading =
    status === 'loading' ||
    isLoadingCreatingNewTask ||
    isLoadingUpdatingTask ||
    isDeletePending ||
    isCopyPending ||
    isPending;

  const isError = isCreatingNewTaskError || isUpdatingTaskError;
  const error = creatingNewTaskError ?? updatingTaskError;

  // ---- Modal & Filter Helpers ----
  const setFilters = useCallback(
    (newFilters: FilterType) => _setFilters({ ...filters, ...newFilters }),
    [filters]
  );

  const openModal = useCallback(
    (
      modalSetter: React.Dispatch<React.SetStateAction<boolean>>,
      task?: Tasks,
      type?: TasksType
    ) => {
      setCurrentTask(task ?? null);
      setTaskType(type ?? 'CREATE_SPACE');
      modalSetter(true);
    },
    []
  );

  const closeModal = useCallback(
    (modalSetter: React.Dispatch<React.SetStateAction<boolean>>) => {
      setCurrentTask(null);
      setSelectedNodes([]);
      setSelectedUnit('');
      setCurrentStep(1);
      modalSetter(false);
      // Also reset the form
      methods.reset();
    },
    [methods]
  );

  const openCreateSpaceTaskModal = (task?: Tasks) => {
    openModal(setIsCreateSpaceTaskModalOpen, task, 'CREATE_SPACE');
  };
  const closeCreateSpaceTaskModal = () =>
    closeModal(setIsCreateSpaceTaskModalOpen);

  const openChallengeSetTaskModal = (task?: Tasks) => {
    openModal(setIsChallengeSetTaskModalOpen, task, 'CHALLENGE_SET');
  };
  const closeChallengeSetTaskModal = () =>
    closeModal(setIsChallengeSetTaskModalOpen);

  // ---- CRUD Handlers ----
  const handleDeleteTask = (taskId: string) => {
    deleteTask(taskId, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [QueryKeys.TASKS_HOME] });
        showToast('Task deleted successfully', 'success');
      },
      onError: (error: any) => {
        showToast(
          error?.response?.data?.message || 'Something went wrong',
          'error'
        );
      },
    });
  };

  const handleCopyTask = (task: Tasks) => {
    copyTask(task.id, {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: [QueryKeys.TASKS_HOME] });
        showToast('Task copied successfully', 'success');
      },
      onError: (error: any) => {
        showToast(error?.message || 'Something went wrong', 'error');
      },
    });
  };

  const handleEditTask = (task: Tasks) => {
    if (task.type === 'CREATE_SPACE') {
      openCreateSpaceTaskModal(task);
    } else {
      openChallengeSetTaskModal(task);
    }
  };

  // ---- Main Submit (Both Create Space & Challenge Set) ----
  const onSubmitTaskCreation = async (data: TasksUpdateFormData) => {
    if (!teacherId) return;

    // Common success callback
    const commonOnSuccess = (task: { id: string }) => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeys.TASKS_HOME, teacherId],
      });
      methods.reset();
      showToast(
        `Task ${currentTask ? 'updated' : 'created'} successfully`,
        'success'
      );

      // Close whichever modal is open
      if (taskType === 'CREATE_SPACE') {
        closeCreateSpaceTaskModal();
      } else {
        closeChallengeSetTaskModal();
      }

      // If "Submit & Build Assignment"
      if (submitMode === 'assignment') {
        router.push(`/assignments?taskId=${task.id}`);
      }
    };

    // Validate if Challenge Set has at least one challenge
    if (taskType === 'CHALLENGE_SET' && !data.challenges?.length) {
      showToast('Please select at least one challenge', 'error');
      return;
    }

    // CREATE or UPDATE
    if (currentTask) {
      // UPDATE
      updateTask(
        {
          ...data,
          textLabelSetId: data.textLabelSetId ?? '',
        },
        {
          onSuccess: (updatedTask) => {
            commonOnSuccess(updatedTask);
          },
        }
      );
    } else {
      // CREATE
      createTask(
        {
          ...data,
          createdBy: teacherId,
          type: taskType,
          challenges: data.challenges ?? [],
        },
        {
          onSuccess: (newTask) => {
            commonOnSuccess(newTask);
          },
        }
      );
    }
  };

  // ---- Prefill if editing an existing task ----
  useEffect(() => {
    if (currentTask) {
      methods.setValue('displayName', currentTask.displayName);
      methods.setValue(
        'notebookDescription',
        currentTask.notebookDescription ?? ''
      );
      methods.setValue('grade', currentTask.grade);
      methods.setValue('unit', currentTask.unit.displayName);
      methods.setValue('textLabelSetId', currentTask.textLabelSetId ?? '');

      if (currentTask.type === 'CHALLENGE_SET') {
        methods.setValue('challenges', currentTask.challenges);
        setSelectedNodes(currentTask.challenges);
        setSelectedUnit(currentTask.unit.displayName);
      } else {
        // CREATE_SPACE
        methods.setValue('notebookSummary', currentTask.notebookSummary ?? '');
      }
    } else {
      methods.reset();
    }
  }, [currentTask, methods]);

  // ---- Step Navigation ----
  const navigateToStep = useCallback((step: number) => {
    setCurrentStep(step + 1);
  }, []);

  const goNext = useCallback(async () => {
    const isFormValid = await methods.trigger();
    if (isFormValid) {
      startTransition(() => {
        setCurrentStep((prev) => Math.min(prev + 1, maxSteps));
      });
    }
  }, [methods]);

  return (
    <TaskModalContext.Provider
      value={{
        // States
        currentTask,
        taskType,
        submitMode,
        isCreateSpaceTaskModalOpen,
        isChallengeSetTaskModalOpen,
        currentStep,
        selectedNodes,
        selectedUnit,

        // Form
        methods,
        errors,

        // Booleans
        isLoading,
        isError,
        error,
        maxSteps,

        // Data
        filters,
        textLabelsOptions,
        unitsData,

        // Modal handlers
        openCreateSpaceTaskModal,
        closeCreateSpaceTaskModal,
        openChallengeSetTaskModal,
        closeChallengeSetTaskModal,

        // Step-based Nav
        setCurrentStep,
        navigateToStep,
        goNext,

        // Filter
        setFilters,

        // Submission
        onSubmitTaskCreation,
        setSubmitMode,
        setSelectedNodes,
        setSelectedUnit,

        // Additional CRUD
        handleDeleteTask,
        handleCopyTask,
        handleEditTask,
      }}
    >
      {children}
    </TaskModalContext.Provider>
  );
};
